<template>
  <div id="hrConfigration" class="hrConfigration">
    <v-row>
      <v-col cols="12" style="padding-left: 0">
        <div class="title">
          <h1 class="title" style="font-weight: 600">
            {{ $t("HR Configuration") }}
          </h1>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="no-padding"
        style="padding-left: 0 !important"
      >
        <v-tabs v-model="tab" class="tabs" background-color="#ffffff" dark>
          <v-tab
            v-if="permission.workingdays"
            style="font-weight: 600"
            @click="routeTab(0)"
            class="cunstom-tab"
            >{{ $t("Working Days") }}</v-tab
          >
          <v-tab
            v-if="permission.salaryScale"
            style="font-weight: 600"
            class="cunstom-tab"
            @click="routeTab(1)"
            >{{ $t("Salary Scale") }}</v-tab
          >
          <v-tab
            v-if="permission.allowance"
            style="font-weight: 600"
            class="cunstom-tab"
            @click="routeTab(2)"
            >{{ $t("Allowance/Deductions") }}</v-tab
          >

          <v-tab
            v-if="permission.vacation"
            style="font-weight: 600"
            class="cunstom-tab"
            @click="routeTab(3)"
            >{{ $t("Vacation Reasons") }}</v-tab
          >

          <v-tab
            v-if="permission.permission"
            style="font-weight: 600"
            class="cunstom-tab"
            @click="routeTab(4)"
            >{{ $t("Permission Reasons") }}</v-tab
          >
          <!-- sms stmp configration -->
          <!-- <v-tab @click="routeTab(3)">Email and SMS</v-tab> -->
          <!-- Warehouse -->
          <!-- <v-tab v-if="viewWarwhouse" @click="routeTab(4)">Warehouse</v-tab> -->
        </v-tabs>
      </v-col>
    </v-row>

    <v-card class="padding-top">
      <v-tabs-items v-model="tab" touchless>
        <!-- Working Days -->
        <v-tab-item v-if="permission.workingdays">
          <v-card flat>
            <v-card-text>
              <shiftsComponent></shiftsComponent>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- Salary Scale -->
        <v-tab-item v-if="permission.salaryScale">
          <v-card flat>
            <v-card-text>
              <salaryJobComponent></salaryJobComponent>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- Allowance/Deduction -->
        <v-tab-item v-if="permission.allowance">
          <v-card flat>
            <v-card-text>
              <allowanceComponent></allowanceComponent>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- Vacation Reasons -->
        <v-tab-item v-if="permission.vacation">
          <v-card flat>
            <v-card-text>
              <vacationComponent></vacationComponent>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- Permission Reasons -->
        <v-tab-item v-if="permission.permission">
          <v-card flat>
            <v-card-text>
              <permissionComponent></permissionComponent>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import shiftsComponent from "../../components/hr/shiftsComponent";
import salaryJobComponent from "../../components/hr/salaryJobComponent";
import allowanceComponent from "../../components/hr/allowanceComponent";
import vacationComponent from "../../components/hr/vacationComponent";
import permissionComponent from "../../components/hr/permissionComponent";
import ACL from "../../acl";

export default {
  name: "HrConfigration",
  components: {
    shiftsComponent,
    salaryJobComponent,
    allowanceComponent,
    vacationComponent,
    permissionComponent,
  },

  data() {
    return {
      balance: "",
      timeout: 600,
      loading: false,
      tab: 0,

      permission: {
        workingdays: ACL.checkPermission("HR-config-working-day"),
        salaryScale: ACL.checkPermission("HR-config-salary-scale"),
        allowance: ACL.checkPermission("HR-config-allowance"),
        vacation: ACL.checkPermission("HR-config-vacation-reasons"),
        permission: ACL.checkPermission("HR-config-permission-list"),
      },
    };
  },
  computed: {},
  watch: {
    $route: {
      handler() {
        if (this.$route.query.tab !== undefined) {
          this.tab = Number(this.$route.query.tab);
        }
      },
    },

    page: function() {
      this.$router.push(
        {
          path: "/workingdays?tab=0?page=" + this.page,
        },
        () => {}
      );
    },

    search: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
    // filter: {
    //   handler() {
    //     this.page = 1;
    //     this.getAllData(this.page);
    //   },
    //   deep: true,
    // },
  },
  methods: {
    routeTab(tab) {
      this.$router.push(
        {
          path: "/hrConfigrations?tab=" + tab + "&page=1",
        },
        () => {}
      );
      this.tab = tab;
      console.log(tab);
    },
  },
  mounted() {
    this.page = 1;
    this.tab = Number(this.$route.query.tab);
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.checkbox {
  display: inline-block;
}
.chip-item {
  display: inline-block;
  margin: 10px;
}

p.colTitle {
  font-size: 1.3rem;
}
p.notice {
  font-size: 0.8rem;
  text-align: center;
  position: relative;
}
p.notice:after {
  content: "";
  width: 70%;
  height: 1px;
  background-color: #ccc;
  position: absolute;
  bottom: 0;
  left: 15%;
}

.radio-section {
  padding: 5px 12px;
}

.v-color-picker {
  margin-bottom: 1rem;
}
.colorCode {
  border: 1px solid $main-color;
  padding: 5px;
  border-radius: 5px;
  background-color: $main-background;
  min-width: 70px;
  display: inline-block;
  min-height: 25px;
}

.workingDaysContainer {
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

#addDay {
  span {
    cursor: pointer;
  }
}

.padding-top {
  padding: 0 0;
  padding-top: 1rem;
  margin-left: -33px;
}

.v-chip-group .v-chip {
  margin: 4px 8px 4px 0 !important;
}

.v-application .mx-2:first-child {
  margin-left: 12px !important;
}

.filter-p {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  display: inline;
  cursor: pointer;
}
</style>
