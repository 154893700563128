<template>
  <div class="shifts" style="padding-left: 20px">
    <v-row>
      <v-col cols="12" md="12">
        <div class="item-form">
          <label class="main-color" style="font-size: 16px">{{
            $t("Paid Vacation Days")
          }}</label>
          <div>
            <div
              class="chip-item"
              v-for="(day, index) in nonWorkingDayes"
              :key="index"
            >
              <v-chip class="ma-2" color="#7297FF" style="color: #fff">{{
                day.date_format
              }}</v-chip>
              <br />
              <p class="text-center">{{ day.name }}</p>
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="12" cols="12">
        <label class="main-color" style="font-size: 16px">{{
          $t("Month Start Date End Date")
        }}</label>
        <v-form ref="form1" v-model="validMonth">
          <v-row>
            <v-col cols="6" md="4">
              <label>{{ $t("Start") }}</label>
              <v-select
                solo
                v-model="month.start"
                @change="ChooseEnd"
                :rules="[validationRules.required]"
                :items="[
                  { text: '1', value: '1' },
                  { text: '2', value: '2' },
                  { text: '3', value: '3' },
                  { text: '4', value: '4' },
                  { text: '5', value: '5' },
                  { text: '6', value: '6' },
                  { text: '7', value: '7' },
                  { text: '8', value: '8' },
                  { text: '9', value: '9' },
                  { text: '10', value: '10' },
                  { text: '11', value: '11' },
                  { text: '12', value: '12' },
                  { text: '13', value: '13' },
                  { text: '14', value: '14' },
                  { text: '15', value: '15' },
                  { text: '16', value: '16' },
                  { text: '17', value: '17' },
                  { text: '18', value: '18' },
                  { text: '19', value: '19' },
                  { text: '20', value: '20' },
                  { text: '21', value: '21' },
                  { text: '22', value: '22' },
                  { text: '23', value: '23' },
                  { text: '24', value: '24' },
                  { text: '25', value: '25' },
                  { text: '26', value: '26' },
                  { text: '27', value: '27' },
                  { text: '28', value: '28' },
                  { text: '29', value: '29' },
                  { text: '30', value: '30' },
                  { text: '31', value: '31' },
                ]"
              ></v-select>
            </v-col>
            <v-col cols="6" md="4">
              <label>{{ $t("End") }}</label>
              <v-select
                disabled
                v-model="month.end"
                :rules="[validationRules.required]"
                solo
                :items="[
                  { text: '1', value: '1' },
                  {
                    text: '2',
                    value: '2',
                  },
                  { text: '3', value: '3' },
                  { text: '4', value: '4' },
                  { text: '5', value: '5' },
                  { text: '6', value: '6' },
                  { text: '7', value: '7' },
                  { text: '8', value: '8' },
                  { text: '9', value: '9' },
                  { text: '10', value: '10' },
                  { text: '11', value: '11' },
                  { text: '12', value: '12' },
                  { text: '13', value: '13' },
                  { text: '14', value: '14' },
                  { text: '15', value: '15' },
                  { text: '16', value: '16' },
                  { text: '17', value: '17' },
                  { text: '18', value: '18' },
                  { text: '19', value: '19' },
                  { text: '20', value: '20' },
                  { text: '21', value: '21' },
                  { text: '22', value: '22' },
                  { text: '23', value: '23' },
                  { text: '24', value: '24' },
                  { text: '25', value: '25' },
                  { text: '26', value: '26' },
                  { text: '27', value: '27' },
                  { text: '28', value: '28' },
                  { text: '29', value: '29' },
                  { text: '30', value: '30' },
                  { text: '31', value: '31' },
                ]"
              ></v-select>
            </v-col>

            <v-col cols="6" md="4">
              <v-btn
                class="modal-btn-save saveBtn"
                style="margin-top: 20px"
                large
                @click="saveMonthData"
                :loading="loading"
                :disabled="loading"
                >{{ $t("Save") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col md="12" cols="12">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headersShifts"
          :items="shiftsItems"
          item-key="id"
          :show-select="false"
          :items-per-page="10"
          class="elevation-1 level1 templates"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="6" sm="12">
                <label
                  class="main-color"
                  style="font-size: 16px; margin-top: 5px"
                  >{{ $t("Shifts") }}</label
                >
              </v-col>

              <v-col md="6" sm="12" class="text-right">
                <v-icon
                  style="font-size: 36px; cursor: pointer"
                  class="main-color"
                  large
                  @click="openDialog()"
                  :title="$t('Add New')"
                  >add_circle</v-icon
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              class="main-color"
              :id="item.id"
              @click="editShift(item)"
              :title="$t('Edit')"
              >edit</v-icon
            >

            <v-icon
              class="main-color"
              :id="item.id"
              @click="openConfirm(item)"
              :title="$t('Delete')"
              >delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- ======================================== Pagination ======================================  -->
    <div class="text-center pt-2">
      <v-pagination
        v-if="length > 0 && totalItems > 0"
        v-model="page"
        :length="length"
        :circle="circle"
        :page="page"
        :total-visible="totalVisible"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
      ></v-pagination>
    </div>

    <!-- Add edit none working day item -->
    <v-dialog v-model="addEditDialog" persistent max-width="650">
      <v-card>
        <v-card-text>
          <v-row class="padding-top">
            <v-col cols="12" md="6">
              <v-card-title class="headline">{{ $t("Shift") }}</v-card-title>
            </v-col>
          </v-row>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <label>{{ $t("Name") }} </label>
                  <v-text-field
                    v-model="editShiftItem.en.name"
                    :rules="[
                      validationRules.required,
                      validationRules.maxLength50,
                    ]"
                    solo
                  ></v-text-field>
                  <div v-if="validation_errors.en.name">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.en.name"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <label>{{ $t("Arabic Name") }} </label>
                  <v-text-field
                    v-model="editShiftItem.ar.name"
                    :rules="[
                      CheckArabicCharactersOnly(editShiftItem.ar.name),
                      validationRules.maxLength50,
                    ]"
                    solo
                  ></v-text-field>
                  <div v-if="validation_errors.ar.name">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.ar.name"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <label>{{ $t("Days") }}</label>
                  <div>
                    <div class="checkbox-item">
                      <v-checkbox
                        v-model="editShiftItem.days"
                        :label="$i18n.t('Saturday')"
                        color="primary darken-3"
                        value="1"
                        class="checkbox"
                        hide-details
                      ></v-checkbox>
                    </div>
                    <div class="checkbox-item">
                      <v-checkbox
                        v-model="editShiftItem.days"
                        :label="$i18n.t('Sunday')"
                        color="primary darken-3"
                        value="2"
                        class="checkbox"
                        hide-details
                      ></v-checkbox>
                    </div>
                    <div class="checkbox-item">
                      <v-checkbox
                        v-model="editShiftItem.days"
                        :label="$i18n.t('Monday')"
                        color="primary darken-3"
                        value="3"
                        class="checkbox"
                        hide-details
                      ></v-checkbox>
                    </div>
                    <div class="checkbox-item">
                      <v-checkbox
                        v-model="editShiftItem.days"
                        :label="$i18n.t('Tuesday')"
                        color="primary darken-3"
                        value="4"
                        class="checkbox"
                        hide-details
                      ></v-checkbox>
                    </div>
                    <div class="checkbox-item">
                      <v-checkbox
                        v-model="editShiftItem.days"
                        :label="$i18n.t('Wednesday')"
                        color="primary darken-3"
                        value="5"
                        class="checkbox"
                        hide-details
                      ></v-checkbox>
                    </div>
                    <div class="checkbox-item">
                      <v-checkbox
                        v-model="editShiftItem.days"
                        :label="$i18n.t('Thursday')"
                        color="primary darken-3"
                        value="6"
                        class="checkbox"
                        hide-details
                      ></v-checkbox>
                    </div>
                    <div class="checkbox-item">
                      <v-checkbox
                        v-model="editShiftItem.days"
                        :label="$i18n.t('Friday')"
                        color="primary darken-3"
                        value="7"
                        class="checkbox"
                        hide-details
                      ></v-checkbox>
                    </div>
                    <div v-if="validation_errors.days">
                      <p
                        class="red--text"
                        v-for="(error, index) in validation_errors.days"
                        :key="index"
                      >
                        {{ error }}
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editShiftItem.from"
                    transition="scale-transition"
                    offset-y
                  >
                    <!-- max-width="200px"
                    min-width="180px" -->
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editShiftItem.from"
                        :label="$i18n.t('From')"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[
                          validationRules.required,
                          startTimeRule(
                            editShiftItem.from,
                            editShiftItem.to,
                            $i18n.t('Start time should be less than end time')
                          ),
                        ]"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      v-model="editShiftItem.from"
                      full-width
                      @click:minute="$refs.menu.save(editShiftItem.from)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    ref="men"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editShiftItem.to"
                    transition="scale-transition"
                    offset-y
                  >
                    <!-- max-width="200px"
                    min-width="180px" -->
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editShiftItem.to"
                        :label="$i18n.t('To')"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[
                          validationRules.required,
                          startTimeRule(
                            editShiftItem.from,
                            editShiftItem.to,
                            $i18n.t('End time should be more than start time')
                          ),
                        ]"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu3"
                      v-model="editShiftItem.to"
                      full-width
                      @click:minute="$refs.men.save(editShiftItem.to)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="closeAddEditDialog">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            class="modal-btn-save"
            @click="save()"
            :loading="loading"
            :disabled="loading"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete push notifcations -->
    <v-dialog
      v-model="openConfirmDialog"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to Delete this Shift ?")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="openConfirmDialog = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deleteItem(editShiftItem.id)"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete error dialog -->
    <v-dialog
      v-model="opendeleteErrorialog"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{ $t("Oops!") }}</v-card-title>

        <v-card-text>{{
          $t("You have To Add Another Shift First!")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="opendeleteErrorialog = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete if shift has employees  dialog -->
    <v-dialog
      v-model="opendShiftHasEmployeesDialog"
      persistent
      max-width="400"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline main-color">{{
          $t("Delete Shift")
        }}</v-card-title>

        <v-card-text
          >{{ $t("There are ") }}{{ editShiftItem.hasEmployeesCount }}
          {{ $t("Employees assigned to") }}
          <strong>{{ editShiftItem.name }}</strong> .
          {{ $t("Please select another Shift For Them .") }}
          <br />
          <div>
            <v-autocomplete
              :items="shiftsSwithItems"
              @change="hideLabel = true && (errorshiftsSwithItems = false)"
              item-text="name"
              item-value="id"
              v-model="shiftSwitcher"
              chips
              solo
            >
            </v-autocomplete>
            <p style="color: red" v-if="errorshiftsSwithItems">
              {{ $t("This field is required") }}
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="closeShiftHasEmployeesDialog"
            id="noBtn"
            ref="always_focus"
            >{{ $t("close") }}</v-btn
          >

          <v-btn
            class="modal-btn-save yesBtn"
            @click="confirmDeleteandSwetch()"
            ref="always_focus"
            >{{ $t("Confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" color="success" :right="true" :top="true">
      {{ snackText }}
      <v-btn color="pink" text @click="snackbar = false">{{
        $t("Close")
      }}</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
// import moment from "moment";
import { validationMixin } from "../../mixins/validationMixin";

// import ACL from "../../acl";

export default {
  name: "shiftsComponent",

  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      month: {
        start: "",
        end: "",
      },
      menu2: false,
      menu3: false,
      dayesValid: false,
      dialogReset: false,
      balance: "",
      timeout: 600,
      loading: false,
      openConfirmDialog: false,
      opendeleteErrorialog: false,
      opendShiftHasEmployeesDialog: false,
      headersShifts: [
        {
          text: this.$i18n.t("Name"),
          value: "en.name",
          sortable: false,
        },
        {
          text: this.$i18n.t("Arabic Name"),
          value: "ar.name",
          sortable: false,
        },
        {
          text: this.$i18n.t("Days"),
          value: "days",
          sortable: false,
        },
        {
          text: this.$i18n.t("Time"),
          value: "time",
          sortable: false,
        },

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],

      editShiftItem: {
        id: "",
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
        from: "",
        to: "",
        days: [],
        hasEmployeesCount: "",
        employeesName: [],
      },
      circle: true,
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
      nonWorkingDayes: [],
      shiftsItems: [],
      validation_errors: {
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
        days: "",
      },
      tab: 0,
      snack: false,
      snackColor: "",
      snackText: "",

      confirmDialog: false,
      addEditDialog: false,
      selected: {},
      shiftsSwithItems: [],
      shiftSwitcher: "",
      errorshiftsSwithItems: false,
      snackbar: false,
      text: "",
      edit: false,
      /* validation */
      valid: true,
      validMonth: true,
    };
  },
  computed: {},
  watch: {
    $route: {
      handler() {
        if (this.$route.query.tab == 0) {
          if (this.$route.query.page !== undefined) {
            this.getAllData(this.$route.query.page);
          } else {
            this.getAllData(1);
          }
        }
        if (this.$route.query.tab !== undefined) {
          this.tab = Number(this.$route.query.tab);
        }
      },
    },

    page: function() {
      this.$router.push(
        {
          path: "/hrConfigrations?tab=0&page=" + this.page,
        },
        () => {}
      );
    },

    search: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
    filter: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
  },
  methods: {
    ChooseEnd() {
      if (this.month.start != "1") {
        this.month.end = (Number(this.month.start) - 1).toString();
      } else {
        this.month.end = "30";
      }
    },
    MonthCheck() {
      return this.month.start > this.month.end
        ? "End should be more than start"
        : true;
    },
    saveMonthData() {
      if (!this.validMonth) {
        this.$refs.form1.validate();
      } else {
        this.loading = true;
        axios
          .post(
            this.getApiUrl +
              "/hrConfigration/AddMonthStartEnd/" +
              localStorage.school,
            this.month,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            this.snackbar = true;
            this.snackText = response.data.status.message;
          });
      }
    },
    getNonWorkingDays() {
      axios
        .get(this.getApiUrl + "/hrConfigration/non_working_days", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.nonWorkingDayes = response.data.data;
          this.loading = false;
        });
    },

    cleanData() {
      this.editShiftItem.id = "";
      this.editShiftItem.en.name = "";
      this.editShiftItem.ar.name = "";
      this.editShiftItem.from = "";
      this.editShiftItem.to = "";
      this.editShiftItem.days = [];
      this.editShiftItem.hasEmployeesCount = "";
      this.editShiftItem.employeesName = [];

      this.validation_errors = {
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
        days: "",
      };
      // this.$refs.form.resetValidation();
    },
    save() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.loading = true;
        if (this.editShiftItem.id != "") {
          axios
            .post(
              this.getApiUrl +
                "/hrConfigration/updateShift/" +
                this.editShiftItem.id,
              this.editShiftItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.addEditDialog = false;
                this.cleanData();
                this.getAllData(this.page);
              }
            });
        } else {
          this.loading = true;
          axios
            .post(
              this.getApiUrl + "/hrConfigration/storeShift",
              this.editShiftItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.addEditDialog = false;
                this.cleanData();
                this.getAllData(this.page);
              }
            });
        }
        // this.addEditDialog = true;
      }
    },
    openDia(item) {
      this.$refs.form.resetValidation();
      this.addEditDialog = true;
      this.editedItem = item;
    },
    editShift(item) {
      this.editShiftItem.id = item.id;
      this.editShiftItem.en.name = item.en.name;
      this.editShiftItem.ar.name = item.ar.name;
      this.editShiftItem.from = item.from;
      this.editShiftItem.to = item.to;
      this.editShiftItem.days = item.daysIds;
      this.addEditDialog = true;
    },
    closeAddEditDialog() {
      this.addEditDialog = false;
      this.cleanData();
      this.$refs.form.resetValidation();
    },
    deleteItem(id) {
      // console.log(item);

      let swetcherId = this.shiftSwitcher;

      axios
        .get(
          this.getApiUrl +
            "/hrConfigration/destroy/" +
            id +
            "?swetcherId=" +
            swetcherId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data.status.error);
          if (response.data.status.error == false) {
            this.openConfirmDialog = false;
            this.opendShiftHasEmployeesDialog = false;
            // console.log(response.data);
            this.snack = true;
            this.snackColor = "green";
            this.snackText = response.data.status.message;
            this.cleanData();
            this.getAllData(this.page);
            window.location.reload();
          } else {
            this.cleanData();
            this.openConfirmDialog = false;
            this.opendeleteErrorialog = true;
          }
        });
    },
    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl + "/hrConfigration/shifts?tab=0&page=" + this.page,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.shiftsItems = [];
          this.shiftsItems = response.data.data.data;
          this.loading = false;
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
    onFocus() {
      this.validation_errors = {
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
        subject_id: "",
        qualifier_id: "",
        mark: "",
        exam_date: "",
        academic_year_id: "",
      };
    },
    inputFocus() {
      $("#inpt_search")
        .parent("label")
        .addClass("active");
      $(".input_search")
        .parent("label")
        .addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search")
          .parent("label")
          .removeClass("active");
        $(".input_search")
          .parent("label")
          .removeClass("active");
      }
    },

    routeTab(tab) {
      this.$router.push(
        {
          path: "/hrConfigrations?tab=" + tab,
        },
        () => {}
      );
      this.tab = tab;
      console.log(tab);
    },

    openConfirm(item) {
      if (this.shiftsItems.length <= 1) {
        this.opendeleteErrorialog = true;
      } else {
        this.editShiftItem = item;
        this.editShiftItem.id = item.id;
        if (item.hasEmployeesCount > 0) {
          axios
            .get(
              this.getApiUrl +
                "/hrConfigration/getShfits/" +
                this.editShiftItem.id,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.shiftsSwithItems = response.data.data;
              this.opendShiftHasEmployeesDialog = true;
            });
        } else {
          this.openConfirmDialog = true;
        }
      }
    },
    closeShiftHasEmployeesDialog() {
      this.shiftSwitcher = "";
      this.opendShiftHasEmployeesDialog = false;
    },
    confirmDeleteandSwetch() {
      if (this.shiftSwitcher == "") {
        this.errorshiftsSwithItems = true;
      } else {
        this.deleteItem(this.editShiftItem.id);
      }
    },
    closeConfirmDialog() {
      this.openConfirmDialog = false;
    },

    openDialog() {
      this.addEditDialog = true;
      this.cleanData();
    },
    getMonthStartEnd() {
      axios
        .get(
          this.getApiUrl +
            "/hrConfigration/getMonthStartEnd/" +
            localStorage.school,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.month = response.data.data;
        });
    },
  },
  mounted() {
    this.page = 1;
    this.getAllData();
    this.getNonWorkingDays();
    this.getMonthStartEnd();
    this.tab = Number(this.$route.query.tab);
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.checkbox {
  display: inline-block;
}
.chip-item {
  display: inline-block;
  margin: 10px;
  margin-left: 0;
  margin-bottom: 0;
}
#hrConfigration .theme--dark.v-tabs > .v-tabs-bar .v-tab--active {
  border: 0;
  border-bottom: 4px solid;
}
p.colTitle {
  font-size: 1.3rem;
}
p.notice {
  font-size: 0.8rem;
  text-align: center;
  position: relative;
}
p.notice:after {
  content: "";
  width: 70%;
  height: 1px;
  background-color: #ccc;
  position: absolute;
  bottom: 0;
  left: 15%;
}

.radio-section {
  padding: 5px 12px;
}

.v-color-picker {
  margin-bottom: 1rem;
}
.colorCode {
  border: 1px solid $main-color;
  padding: 5px;
  border-radius: 5px;
  background-color: $main-background;
  min-width: 70px;
  display: inline-block;
  min-height: 25px;
}

.workingDaysContainer {
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

#addDay {
  span {
    cursor: pointer;
  }
}

.padding-top {
  padding: 0 0;
  padding-top: 1rem;
  margin-left: -33px;
}

.v-chip-group .v-chip {
  margin: 4px 8px 4px 0 !important;
}

.v-application .mx-2:first-child {
  margin-left: 12px !important;
}

.filter-p {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  display: inline;
  cursor: pointer;
}
.checkbox-item {
  display: inline-block;
  margin-right: 10px;
}
</style>
