<template>
  <div class="shifts" style="padding-left: 20px">
    <v-row>
      <v-col md="12" cols="12">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headersShifts"
          :items="items"
          item-key="id"
          :show-select="false"
          :items-per-page="10"
          class="elevation-1 level1 templates"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="6" sm="12">
                <!-- <label class="main-color" style="font-size: 16px"
                  >Salary Scale</label
                > -->
              </v-col>

              <v-col md="6" sm="12" class="text-right">
                <!-- search -->
                <label class="search" for="inpt_search">
                  <input
                    id="inpt_search"
                    class="input_search"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    type="text"
                    :title="$t('Search')"
                    v-model="search"
                  />
                </label>
                <!-- Filter -->
                <span :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-text-field
                          v-model="salaryFrom"
                          solo
                          :placeholder="$i18n.t('Salary From')"
                        ></v-text-field>
                      </v-list-item>

                      <v-list-item class="select">
                        <v-text-field
                          v-model="salaryTo"
                          solo
                          :placeholder="$i18n.t('Salary To')"
                        ></v-text-field>
                      </v-list-item>

                      <v-card-actions> </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
                <!-- add new btn -->
                <v-icon
                  style="font-size: 36px; cursor: pointer"
                  class="main-color"
                  large
                  @click="openDialog()"
                  :title="$t('Add New')"
                  >add_circle</v-icon
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              class="main-color"
              :id="item.id"
              @click="editJob(item)"
              :title="$t('Edit')"
              >edit</v-icon
            >

            <v-icon
              class="main-color"
              :id="item.id"
              @click="openConfirm(item)"
              :title="$t('Delete')"
              >delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- ======================================== Pagination ======================================  -->
    <div class="text-center pt-2">
      <v-pagination
        v-if="length > 0 && totalItems > 0"
        v-model="page"
        :length="length"
        :circle="circle"
        :page="page"
        :total-visible="totalVisible"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
      ></v-pagination>
    </div>

    <!-- Add edit none salary job item -->
    <v-dialog v-model="addEditDialog" persistent max-width="500">
      <v-card>
        <v-card-text>
          <v-row class="padding-top">
            <v-col cols="12" md="6">
              <v-card-title class="headline">{{
                $t("Add Salary")
              }}</v-card-title>
            </v-col>
          </v-row>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Job Title") }}</label>
                  <v-text-field
                    v-model="editItem.en.name"
                    :rules="[validationRules.required]"
                    solo
                  ></v-text-field>
                  <div v-if="validation_errors.en.name">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.en.name"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Arabic Name") }}</label>
                  <v-text-field
                    v-model="editItem.ar.name"
                    :rules="[
                      validationRules.required,
                      CheckArabicCharactersOnly(editItem.ar.name),
                    ]"
                    solo
                  ></v-text-field>
                  <div v-if="validation_errors.ar.name">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.ar.name"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="8" md="8">
                  <label>{{ $t("Basic Salary") }}</label>
                  <v-text-field
                    v-model="editItem.base_salary"
                    :rules="[
                      validationRules.required,
                      validationRules.positiveFloatNumberNozro,
                      validationRules.positiveFloatNumber,
                      validationRules.maxLength13,
                    ]"
                    solo
                  ></v-text-field>
                  <div v-if="validation_errors.base_salary">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.base_salary"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="4" md="4"
                  ><label></label>
                  <div class="text-sar" style="margin-top: 10%">
                    <h3 style="color: #888">{{ $t("SAR") }}</h3>
                  </div></v-col
                >
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="closeAddEditDialog">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            class="modal-btn-save"
            @click="save()"
            :loading="loading"
            :disabled="loading"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete salary job -->
    <v-dialog
      v-model="openConfirmDialog"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to Delete this Salary ?")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="openConfirmDialog = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deleteItem(editItem.id)"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" color="success" :right="true" :top="true">
      {{ snackText }}
      <v-btn color="pink" text @click="snackbar = false">{{
        $t("Close")
      }}</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
// import moment from "moment";
import { validationMixin } from "../../mixins/validationMixin";

// import ACL from "../../acl";

export default {
  name: "salaryJobComponent",

  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      menu2: false,
      menu3: false,
      dayesValid: false,
      dialogReset: false,
      balance: "",
      timeout: 600,
      loading: false,
      openConfirmDialog: false,
      opendeleteErrorialog: false,
      headersShifts: [
        {
          text: this.$i18n.t("Job Title"),
          value: "en.name",
          sortable: false,
        },
        {
          text: this.$i18n.t("Arabic Name"),
          value: "ar.name",
          sortable: false,
        },
        {
          text: this.$i18n.t("Basic Salary"),
          value: "base_salary",
          sortable: false,
        },

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],

      editItem: {
        id: "",
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
        base_salary: "",
      },
      circle: true,
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
      items: [],
      validation_errors: {
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
        base_salary: "",
      },
      tab: 1,
      snack: false,
      snackColor: "",
      snackText: "",
      search: "",
      confirmDialog: false,
      addEditDialog: false,
      selected: {},
      snackbar: false,
      text: "",
      edit: false,
      valid: true,
      filter: {
        from: "",
        to: "",
      },
      salaryFrom: "",
      salaryTo: "",
      filterMenu: false,
    };
  },
  computed: {},
  watch: {
    $route: {
      handler() {
        if (this.$route.query.tab == 1) {
          if (this.$route.query.page !== undefined) {
            this.getAllData(this.$route.query.page);
          } else {
            this.getAllData(1);
          }
        }
        if (this.$route.query.tab !== undefined) {
          this.tab = Number(this.$route.query.tab);
        }
      },
    },

    page: function() {
      this.$router.push(
        {
          path: "/hrConfigrations?tab=1&page=" + this.page,
        },
        () => {}
      );
      // this.getAllData(this.page);
    },

    search: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
    salaryFrom: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
    salaryTo: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
  },
  methods: {
    clearData() {
      this.editItem.id = "";
      this.editItem.en.name = "";
      this.editItem.ar.name = "";
      this.editItem.base_salary = "";
      this.validation_errors.en.name = "";
      this.validation_errors.ar.name = "";
      this.$refs.form.resetValidation();

      // this.valid = true;
    },
    save() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.loading = true;
        if (this.editItem.id != "") {
          this.loading = true;
          axios
            .post(
              this.getApiUrl +
                "/hrConfigration/updateeSalaryJobs/" +
                this.editItem.id,
              this.editItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.addEditDialog = false;
                this.clearData();
                this.getAllData(this.page);
              }
            });
        } else {
          this.loading = true;
          axios
            .post(
              this.getApiUrl + "/hrConfigration/storeSalaryJobs",
              this.editItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.addEditDialog = false;
                this.closeAddEditDialog();
                this.getAllData(this.page);
              }
            });
        }
        // this.addEditDialog = true;
      }
    },
    openDia(item) {
      this.addEditDialog = true;
      this.editItem = item;
    },
    editJob(item) {
      this.editItem.id = item.id;
      this.editItem.en.name = item.en.name;
      this.editItem.ar.name = item.ar.name;
      this.editItem.base_salary = item.base_salary;

      this.addEditDialog = true;
    },
    closeAddEditDialog() {
      this.clearData();
      this.addEditDialog = false;
    },
    deleteItem(id) {
      // console.log(item);

      axios
        .get(this.getApiUrl + "/hrConfigration/destroySalaryJobs/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          console.log(response.data.status.error);
          if (response.data.status.error == false) {
            this.openConfirmDialog = false;
            this.clearData();
            this.getAllData(this.page);
          }
        });
    },
    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl +
            "/hrConfigration/salaryJobs?tab=0&search=" +
            this.search +
            "&page=" +
            this.page +
            "&from=" +
            this.salaryFrom +
            "&to=" +
            this.salaryTo,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
    onFocus() {
      this.validation_errors = {
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
        base_salary: "",
      };
    },
    inputFocus() {
      $("#inpt_search")
        .parent("label")
        .addClass("active");
      $(".input_search")
        .parent("label")
        .addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search")
          .parent("label")
          .removeClass("active");
        $(".input_search")
          .parent("label")
          .removeClass("active");
      }
    },

    routeTab(tab) {
      this.$router.push(
        {
          path: "/hrConfigrations?tab=" + tab,
        },
        () => {}
      );
      this.tab = tab;
      console.log(tab);
    },

    openConfirm(item) {
      this.editItem.id = item.id;
      this.openConfirmDialog = true;
    },
    closeConfirmDialog() {
      this.openConfirmDialog = false;
    },

    openDialog() {
      this.addEditDialog = true;
    },
  },
  mounted() {
    this.page = 1;
    this.getAllData();

    this.tab = Number(this.$route.query.tab);
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.checkbox {
  display: inline-block;
}
.chip-item {
  display: inline-block;
  margin: 10px;
  margin-left: 0;
}
#hrConfigration .theme--dark.v-tabs > .v-tabs-bar .v-tab--active {
  border: 0;
  border-bottom: 4px solid;
}
p.colTitle {
  font-size: 1.3rem;
}
p.notice {
  font-size: 0.8rem;
  text-align: center;
  position: relative;
}
p.notice:after {
  content: "";
  width: 70%;
  height: 1px;
  background-color: #ccc;
  position: absolute;
  bottom: 0;
  left: 15%;
}

.radio-section {
  padding: 5px 12px;
}

.v-color-picker {
  margin-bottom: 1rem;
}
.colorCode {
  border: 1px solid $main-color;
  padding: 5px;
  border-radius: 5px;
  background-color: $main-background;
  min-width: 70px;
  display: inline-block;
  min-height: 25px;
}

.workingDaysContainer {
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

#addDay {
  span {
    cursor: pointer;
  }
}

.padding-top {
  padding: 0 0;
  padding-top: 1rem;
  margin-left: -33px;
}

.v-chip-group .v-chip {
  margin: 4px 8px 4px 0 !important;
}

.v-application .mx-2:first-child {
  margin-left: 12px !important;
}

.filter-p {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  display: inline;
  cursor: pointer;
}
</style>
